import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { graphql, useStaticQuery } from 'gatsby'
import dayjs from 'dayjs'
import { Article } from '~src/types/article'
import { findImages } from '~components/homepage/utils'
import { DefaultImage } from '~components/homepage/styled/shared'
import { mediaQueries } from '~components/homepage/styled/constants'
import { ImagesQueryData } from '~src/types/graphql-types'
import {
  ArticleDate,
  ArticleDescription,
  ArticleTitle,
  Divider,
} from './styled/shared'

const HeadArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
  align-items: end;
  margin-bottom: 20px;

  @media (max-width: ${mediaQueries.medium}) {
    & {
      margin-top: 50px;
      grid-template-columns: 1fr;
      padding: 20px;
      gap: 10px;
    }
  }
`

interface Props {
  article: Article
}

const HeadArticle: React.FC<Props> = ({ article }): ReactElement => {
  const data = useStaticQuery<ImagesQueryData>(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const sliderImages = findImages(data, [article.mainPicture.src])

  return (
    <div>
      <HeadArticleContainer>
        <DefaultImage
          fluid={sliderImages[0]?.node.childImageSharp.fluid}
          height="450px"
          width="100%"
        />
        <div>
          <ArticleDate style={{ marginBottom: 15 }}>
            {dayjs(article.date).format('DD.MM.YYYY')}
          </ArticleDate>
          <ArticleTitle style={{ marginBottom: 10 }}>
            {article.title}
          </ArticleTitle>
          <ArticleDescription>
            {[article.content?.[0].content, article.content?.[1].content]
              .join(' ')
              .substring(0, 400)}
            ...
          </ArticleDescription>
        </div>
      </HeadArticleContainer>
      <Divider />
    </div>
  )
}

export default HeadArticle
